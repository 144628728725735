import { useFetch } from '../hooks/useFetch';
import { ITestPushNotification } from '../components/SendTestPush';
import { INotificationEvent } from '../features/Project/Notifications/models';
import moment from 'moment';
import {
  IChangeServingRequest,
  ICloneVersion,
} from '../features/Project/Versions/models';
import {
  IUserIssue,
  IUserIssueTranslate,
} from '../features/Project/UserIssues/models';

export const useProjectService = () => {
  const { request, loading, error } = useFetch();

  const requestNewGame = async (requestObject: object) => {
    return await request(
      `projects/request`,
      'POST',
      JSON.stringify(requestObject)
    );
  };

  const addNewProject = async (requestObject: object) => {
    return await request(`projects`, 'POST', JSON.stringify(requestObject));
  };

  const getAllProjects = async () => {
    return await request('projects');
  };

  const getProjectHeader = async (id: string) => {
    return await request(`projects/${id}/header`);
  };

  const getProjectVersionGroups = async (id: number) => {
    return await request(`projects/${id}/versions/groups`);
  };

  const getProjectVersionList = async (id: number) => {
    return await request(`projects/${id}/versions/list`);
  };

  const compareVersions = async (id: number, versions: string) => {
    const payload = {
      Versions: versions,
    };
    return await request(
      `projects/${id}/versions/compare`,
      'POST',
      JSON.stringify(payload)
    );
  };

  const massCloneVersions = async (id: number, payload: any) => {
    return await request(
      `projects/${id}/versions/mass_clone`,
      'POST',
      JSON.stringify(payload)
    );
  };

  const copyConfigs = async (projectId: number, requestData: object) => {
    return await request(
      `projects/${projectId}/versions/copy-configs`,
      'POST',
      JSON.stringify(requestData)
    );
  };

  const saveServingSettings = async (
    projectID: number,
    requestData: IChangeServingRequest[]
  ) => {
    return await request(
      `projects/${projectID}/versions/serving-settings`,
      'POST',
      JSON.stringify(requestData)
    );
  };

  const deleteVersion = async (projectId: number, versionId: number) => {
    return await request(
      `projects/${projectId}/versions/${versionId}`,
      'DELETE'
    );
  };

  const cloneVersion = async (
    projectId: number,
    versionId: number,
    payload: ICloneVersion
  ) => {
    return await request(
      `projects/${projectId}/versions/${versionId}`,
      'POST',
      JSON.stringify(payload)
    );
  };

  const getSayKitSettings = async (projectID: number) => {
    return await request(`projects/${projectID}/saykit-settings`);
  };

  const downloadProfile = async (projectID: number, kind: string) => {
    return await request(
      `projects/${projectID}/download-apple-profiles?kind=${kind}`,
      'GET',
      null,
      true
    );
  };

  const downloadKeystore = async (projectID: number) => {
    return await request(
      `projects/${projectID}/download-android-keystore`,
      'GET',
      null,
      true
    );
  };

  const getBuilds = async (projectID: number) => {
    return await request(`projects/${projectID}/builds`);
  };

  const getSettings = async (projectID: number) => {
    return await request(`projects/${projectID}/settings`);
  };

  const saveSettings = async (projectID: number, settings: object) => {
    return await request(
      `projects/${projectID}/settings`,
      'PUT',
      JSON.stringify(settings)
    );
  };

  const getMonetization = async (projectID: number) => {
    return await request(`projects/${projectID}/monetization`);
  };

  const getCPIs = async (projectID: number) => {
    return await request(`projects/${projectID}/cpi`);
  };

  const getSupportTickets = async (projectID: number) => {
    return await request(`projects/${projectID}/support`);
  };

  const sendSupportRequest = async (
    projectID: number,
    requestSubjectType: string,
    requestSubject: string,
    requestBody: string,
    requestUrl: string
  ) => {
    const data = {
      requestSubjectType: requestSubjectType,
      requestSubject: requestSubject,
      requestBody: requestBody,
      requestUrl: requestUrl,
    };
    return await request(
      `projects/${projectID}/support`,
      'POST',
      JSON.stringify(data)
    );
  };

  const getSupportBuildComment = async (
    projectID: number,
    requestUrl: string
  ) => {
    const data = {
      requestUrl: requestUrl,
    };
    return await request(
      `projects/${projectID}/support/build_comment`,
      'POST',
      JSON.stringify(data)
    );
  };

  const sendPushNotification = async (
    projectID: number,
    push: ITestPushNotification
  ) => {
    return await request(
      `projects/${projectID}/notifications/test_push`,
      'POST',
      JSON.stringify(push)
    );
  };

  const getNotifications = async (projectID: number) => {
    return await request(`projects/${projectID}/notifications`);
  };

  const addNotification = async (projectID: number, eventType: string) => {
    return await request(
      `projects/${projectID}/notifications`,
      'POST',
      JSON.stringify({
        type: eventType,
      })
    );
  };

  const updateNotification = async (
    projectID: number,
    notification: INotificationEvent
  ) => {
    return await request(
      `projects/${projectID}/notifications`,
      'PUT',
      JSON.stringify(notification)
    );
  };
  const deleteNotification = async (
    projectID: number,
    notificationID: number
  ) => {
    return await request(
      `projects/${projectID}/notifications/${notificationID}`,
      'DELETE'
    );
  };

  const importNotificationsCsv = async (
    projectID: number,
    notificationID: number,
    form: FormData
  ) => {
    return await request(
      `projects/${projectID}/notifications/${notificationID}/csv`,
      'POST',
      form,
      false,
      {
        Login: localStorage.getItem('login')!,
        Role: localStorage.getItem('role')!,
        Hash: localStorage.getItem('hash')!,
        Accept: 'text/csv',
      }
    );
  };

  const exportNotificationsCsv = async (
    projectID: number,
    notificationID: number
  ) => {
    return await request(
      `projects/${projectID}/notifications/${notificationID}/csv`,
      'GET',
      null,
      true
    );
  };

  const loadNotificationCert = async (projectID: number, form: FormData) => {
    return await request(
      `projects/${projectID}/notifications/certificate`,
      'POST',
      form
    );
  };

  const getDeviceLogs = async (
    projectID: number,
    since: string = moment().format('yy-MM-DD'),
    till: string = moment().format('yy-MM-DD'),
    deviceID: string
  ) => {
    return await request(
      `old/devices/${projectID}/${deviceID}/logs/${since}/${till}`
    );
  };

  const exportDeviceLogsAsCSV = async (
    projectID: number,
    deviceID: string,
    since: string = moment().format('yy-MM-DD'),
    till: string = moment().format('yy-MM-DD')
  ) => {
    return await request(
      `old/devices/${projectID}/${deviceID}/logs/${since}/${till}/export`,
      'GET',
      null,
      true
    );
  };

  const getRandomDeviceLogs = async (
    projectID: number,
    since: string,
    till: string,
    version: string
  ) => {
    return await request(
      `old/devices-random/${projectID}/logs/${since}/${till}/${version}`
    );
  };

  const exportRandomDeviceLogsAsCSV = async (
    projectID: number,
    deviceID: string,
    since: string,
    till: string,
    version: string
  ) => {
    return await request(
      `old/devices/${projectID}/${deviceID}/logs/${since}/${till}/export/${version}`,
      'GET',
      null,
      true
    );
  };

  const getLogValidationHistory = async (projectId: number) => {
    return await request(`projects/${projectId}/log-validation/history`);
  };

  const runLogValidation = async (projectId: number, input: object) => {
    return await request(
      `projects/${projectId}/log-validation/validate`,
      'POST',
      JSON.stringify(input)
    );
  };

  const createCustomLogValidation = async (
    projectId: number,
    input: object
  ) => {
    return await request(
      `projects/${projectId}/log-validation`,
      'POST',
      JSON.stringify(input)
    );
  };

  const updateCustomLogValidation = async (
    projectId: number,
    input: object
  ) => {
    return await request(
      `projects/${projectId}/log-validation`,
      'PUT',
      JSON.stringify(input)
    );
  };

  const deleteCustomLogValidation = async (
    projectId: number,
    validationId: number
  ) => {
    return await request(
      `projects/${projectId}/log-validation/${validationId}`,
      'DELETE'
    );
  };

  const generateNextVersions = async (projectId: number, versionID: number) => {
    return await request(
      `projects/${projectId}/versions/${versionID}/generate-next-versions`
    );
  };

  const creatives = async () => {
    return await request(`creatives`, 'GET', null, true);
  };

  const uploadAndroidKeystore = async (projectId: number, form: FormData) => {
    return await request(
      `projects/${projectId}/build-machine/android_keystore`,
      'POST',
      form,
      false,
      {
        Login: localStorage.getItem('login')!,
        Role: localStorage.getItem('role')!,
        Hash: localStorage.getItem('hash')!,
        Accept: 'text/csv',
      }
    );
  };

  return {
    loading,
    error,
    addNewProject,
    requestNewGame,
    getAllProjects,
    getProjectHeader,
    getProjectVersionGroups,
    getProjectVersionList,
    compareVersions,
    massCloneVersions,
    copyConfigs,
    saveServingSettings,
    deleteVersion,
    cloneVersion,
    getSayKitSettings,
    downloadProfile,
    downloadKeystore,
    getBuilds,
    getSettings,
    saveSettings,
    getMonetization,
    getCPIs,
    getSupportTickets,
    sendSupportRequest,
    getSupportBuildComment,
    sendPushNotification,
    getNotifications,
    addNotification,
    updateNotification,
    deleteNotification,
    loadNotificationCert,
    importNotificationsCsv,
    exportNotificationsCsv,
    getDeviceLogs,
    getRandomDeviceLogs,
    exportDeviceLogsAsCSV,
    exportRandomDeviceLogsAsCSV,
    getLogValidationHistory,
    runLogValidation,
    createCustomLogValidation,
    generateNextVersions,
    updateCustomLogValidation,
    deleteCustomLogValidation,
    creatives,
    uploadAndroidKeystore,
  };
};
